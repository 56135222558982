import React, { Component } from "react";
import axios from "axios";

import { connect } from 'react-redux';
import { bindActionCreators } from "redux";

import FormCampaignEdit from "../form/FormCampaignEdit";
import Modal from '../../modal/Modal';

import {setCampaignCode} from "../../table/filters/FilterActionss";

import moment from 'moment';

class ListCampaigns extends Component {
  constructor(props) {
    super(props);
    this.showEditCampaignForm = this.showEditCampaignForm.bind(this);

    this.state = {
      partners: [],
      partner: [],
      partner_code: "",
      partner_campaigns: [],
      partner_campaigns_ordered: [],

      selected_campaign: {},

      editFormVisible: false,
      loadingPartners: true,
      partnerHasCampaigns: false,
      campaignsMessage: "",
      accountingProcessedFiles: [],

      startHourFormat: '',
      deadlineHourFormat: '',

      text_editor_content_html: '',
      campaign_rule_id: '',
      regulation_click: false,

      modal: false,
      tipo: '',
    };
    this.regulationRef = React.createRef();
  }

  componentDidUpdate() {

    if (this.state.regulation_click) {
      const BASE_URL = `${process.env.REACT_APP_URL}`;

      var replacedCampaignName = this.state.selected_campaign.campaignName;
      var replacedCampaignCode = this.state.selected_campaign.campaignCode;

      axios
        .get(
          `${BASE_URL}/rule/${replacedCampaignCode}?campaignName=${replacedCampaignName}`
        )
        .then((response) => {
          var contentHtml = response.data.results.campaignRule;
          
          this.setState({
            text_editor_content_html: contentHtml,
            campaign_rule_id: response.data.results.id,
            regulation_click: false,
          });
          this.regulationRef.current.innerHTML = this.state.text_editor_content_html;
        })
        .catch((error) => {
          if (error.response) {
            const httpStatusError = JSON.stringify(error.response.status);
            if (httpStatusError !== '200') {
              this.props.handleSendFormCampaignFail(
                'Ocorreu um erro ao carregar o regulamento.'
              );
            }
          }
        });
    }
  }

  componentDidMount() {
    const BASE_URL = `${process.env.REACT_APP_URL}`;

    axios
      .get(`${BASE_URL}/partner`)
      .then((response) => {
        var partnersData = response.data.results;

        partnersData.sort(function (a, b) {
          if (a.partnerName < b.partnerName) {
            return -1;
          }
          if (a.partnerName > b.partnerName) {
            return 1;
          }
          return 0;
        });

        partnersData.forEach((partner) => {
          if (partner.campaigns) {
            partner.campaigns.forEach((campaign) => {
              campaign.startDateBrlFormat = this.convertToDateFormat(
                campaign.startDate
              );
              if (campaign.deadline) {
                campaign.deadlineBrlFormat = this.convertToDateFormat(
                  campaign.deadline
                );
              }
            });
            partner.campaigns.sort(function (a, b) {
              if (a.campaignCode < b.campaignCode) {
                return -1;
              }
              if (a.campaignCode > b.campaignCode) {
                return 1;
              }
              return 0;
            });
          }
        });
        if (response.data.results) {
          this.setState({
            partners: partnersData,
            loadingPartners: false,
          });
        } else {
          this.setState({
            partners: [],
            loadingPartners: false,
          });
        }
      })
      .then(() => {
        axios.get(`${BASE_URL}/importacao/all`)
        .then((response) => {
          var processedFile = response.data.results;
          if (response.data.results) {
            this.setState({
              accountingProcessedFiles: processedFile,
            });
          } else {
            this.setState({
              accountingProcessedFiles: [],
            });
          }
        })
        .catch((error) => {
          if (error.response && JSON.stringify(error.response.status) === "401") {
            this.props.logout();
          }
        });
      })
      .catch((error) => {
        if (error.response && JSON.stringify(error.response.status) === "401") {
          this.props.logout();
        }
      });
  }

  convertToDateFormat(rawDate) {
    return moment(rawDate).format('DD/MM/YYYY HH:mm')
  }

  handleChangeSelectPartner = (event) => {
    event.preventDefault();
    if (event.target.value === "select") {
      this.setState({
        partner: {},
        partner_code: "",
        partner_campaigns: [],
        partnerHasCampaigns: false,
        campaignsMessage: "",
        partner_campaigns_ordered: [],
      });
    } else {
      const partner_code_selected = event.target.value;
      const partner_found = this.state.partners.filter((partner) => {
        return partner.partnerCode === partner_code_selected;
      });
      this.setState({
        partner: partner_found[0],
        partner_code: partner_code_selected
      });

      if (partner_found[0].campaigns) {
        this.setState({
          partner_campaigns: partner_found[0].campaigns,
          partnerHasCampaigns: true,
          campaignsMessage: "",
          partner_campaigns_ordered: [],
        });
      } else {
        this.setState({
          partnerHasCampaigns: false,
          campaignsMessage: "Não há campanhas cadastradas para este parceiro.",
          partner_campaigns_ordered: [],
        });
      }
    }
  };

  showEditCampaignForm(campaign) {
    this.setState({
      editFormVisible: true,
      selected_campaign: campaign,
    });
  }

  checkDateToday = (finalDate) => {
    const validatingToday = new Date();

    if(validatingToday.getTime() < finalDate.getTime() || validatingToday.getTime() === finalDate.getTime()){
      return true;
    }
    return false;
  }

  dateComparison = (campaign) => {
    let validatingToday;
    let validation;
    let initialDate = campaign.startDate;
    let finalDate = campaign.deadline;

    let parts = String(initialDate).split('T');
    let days = String(parts[0]).split('-');
    let utc = String(parts[1]).split('.');
    if(this.state.startHourFormat === '') {
      this.setState({
        startHourFormat: utc[0],
      });
    }
    let time = String(utc[0]).split(':');
    let year = days[0];
    let month = days[1]-1;
    let day = days[2];
    let hour = time[0];
    let minutes = time[1];
    let seconds = time[2];

    initialDate = new Date(year,month,day,hour,minutes,seconds);

    if(finalDate === null || finalDate === undefined) {
      return true;
    }

    parts = String(finalDate).split('T');
    days = String(parts[0]).split('-')
    utc = String(parts[1]).split('.');
    if(this.state.deadlineHourFormat === '') {
      this.setState({
        deadlineHourFormat: utc[0]
      })
    }
    time = String(utc[0]).split(':');
    year = days[0];
    month = days[1]-1;
    day = days[2];
    hour = time[0];
    minutes = time[1];
    seconds = time[2];

    finalDate = new Date(year,month,day,hour,minutes,seconds);

    if(initialDate.getTime() === finalDate.getTime()) {
      validation = true;
    }
    else if(initialDate.getTime() < finalDate.getTime()) {
      validation = true;
    }
    else if(initialDate.getTime() > finalDate.getTime()) {
      validation = false;
    }
    
    validatingToday = this.checkDateToday(finalDate);

    if(validation === true && validatingToday === true) {
      return true;
    }
    return false;
  }

  orderPartnerCampaigns = (campaign) => {
    var list = [];
    campaign = Array.from(campaign);

    campaign.map((element) =>  {
      this.dateComparison(element) === true ? (
          list.unshift(element)
      ) : (
          list.push(element)
      )
    });

    if(this.state.partner_campaigns_ordered.length === 0){
      return this.setState({partner_campaigns_ordered: list});
    }
  }

  onAppear = () => {
    this.setState({
      modal:true,
      tipo: 'regulamento',
    });
  }

  onHiden = () => {
    this.setState({
      modal: false,
      tipo: '',
    });
  }

  onClickRegulation = (campaign) => {
    this.setState({
      regulation_click: true,
      selected_campaign: campaign,
    });
    this.onAppear();
  }

  processedFiles = (campaign) => {
    var processedFile = this.state.accountingProcessedFiles;
    var counter = 0;

    processedFile.map((value) => {
      if(campaign.campaignCode === value.campaignCode) {
        if(value.statusProcessed !== undefined) {
          counter++;
        }
      }
    });
    return counter;
  }

  handleProcessedFilesClick = (campaign) => {
    this.props.setCampaignCode(campaign.campaignCode);
    this.props.showImportArea();
  }

  render() {
    return (
      <div>
        {this.state.modal ? (
          <Modal 
            modal={this.state.modal}
            regulationRef={this.regulationRef}
            metodoHiden={this.onHiden}
            tipo={this.state.tipo}
          />
        ) : null}
        {this.state.editFormVisible === false ? (
          <div id="page-wrapper">
            <div id="list-container">
              {this.state.loadingPartners === true ? (
                <div className="loading-msg">Carregando parceiros...</div>
              ) : (
                <div className="select-box">
                  {this.state.loadingPartners === true ? (
                    <div className="loading-msg">Carregando parceiros...</div>
                  ) : (
                    <div id="select-box-list-campaigns">
                      <label>Selecione um parceiro</label>
                      <select
                        onChange={this.handleChangeSelectPartner}
                        value={this.state.partner_code}
                      >
                        <option value="select">Selecione</option>
                        {this.state.partners.map((partner) => (
                          <option
                            key={partner.partnerCode}
                            value={partner.partnerCode}
                          >
                            {partner.partnerName}
                          </option>
                        ))}
                      </select>

                      {this.state.partnerHasCampaigns === true ? (
                        <div>
                          <table className="table-list" id="table-campaigns">
                            <thead>
                              <tr>
                                <th>Código</th>
                                <th>Nome da Campanha</th>
                                <th>Data e Hora de Início</th>
                                <th>Data e Hora Final</th>
                                <th>Status da Campanha</th>
                                <th>Paridade Esfera</th>
                                <th>Paridade Parceiro</th>
                                <th>Visualização do Regulamento</th>
                                <th>Prazo para Crédito dos Pontos (dias)</th>
                                <th>Quantidade de Arquivos Processados</th>
                                <th>Ação</th>
                              </tr>
                            </thead>
                            <tbody>
                              {this.orderPartnerCampaigns(this.state.partner_campaigns)}
                              {this.state.partner_campaigns_ordered.map((campaign) => {
                                return (
                                  <tr key={campaign.campaignCode}>
                                    <td>{campaign.campaignCode}</td>
                                    <td>{campaign.campaignName}</td>
                                    <td>{campaign.startDateBrlFormat}</td>
                                    <td>
                                      {campaign.deadlineBrlFormat ||
                                        "Indefinido"}
                                    </td>
                                    <td>
                                      {this.dateComparison(campaign) === true ? ( "Vigente" ) : ( "Expirada" )}
                                    </td>
                                    <td>{campaign.esferaParity}</td>
                                    <td>{campaign.partnerParity}</td>
                                    <td>
                                      <button
                                        className="button-edit"
                                        onClick={() => this.onClickRegulation(campaign)}
                                      >
                                        Visualizar
                                      </button>
                                    </td>
                                    <td>{campaign.deadlineCreditPoints}</td>
                                    <td>
                                      <button
                                        className="button-edit"
                                        onClick={() => this.handleProcessedFilesClick(campaign)}
                                      >
                                        {this.processedFiles(campaign)}
                                      </button>
                                    </td>
                                    <td>
                                      <button
                                        className="button-edit"
                                        onClick={() =>
                                          this.showEditCampaignForm(campaign)
                                        }
                                      >
                                        Editar
                                      </button>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        <div className="loading-msg">
                          {this.state.campaignsMessage}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              )}

              {this.partners === [] ? (
                <div className="loading-msg">Não há parceiros cadastrados</div>
              ) : null}
            </div>
          </div>
        ) : (
          <FormCampaignEdit
            campaign={this.state.selected_campaign}
            partnerAcceptCategory={this.state.partner.acceptCategory}
            partnerId={this.state.partner.id}
            handleSendFormCampaignSuccess={
              this.props.handleSendFormCampaignSuccess
            }
            handleSendFormCampaignFail={this.props.handleSendFormCampaignFail}
          />
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => ({ campaignCode: state.filter.campaignCode });
const mapDispatchToProps = (dispatch) =>
  bindActionCreators({ setCampaignCode }, dispatch);
export default connect(mapStateToProps, mapDispatchToProps)(ListCampaigns);
